import axios from 'axios';

const onResponseSuccess = (response) => {
  return response;
};

const onResponseFailed = (cf) => {
  if (cf && cf.response) throw cf.response.data
};

const onRequestSuccess = (config) => {
  const signature = localStorage.getItem('signature');
  config.headers['Api-token'] = signature;
  return config;
};

const setupAxiosInterceptors = () => {
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseFailed);

};

export { setupAxiosInterceptors };