import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setOffer } from 'features/offers/actions'

import { apiService } from 'core/services'
import { RootState } from 'core/store/store';
import { paths } from 'core/router/routes';
import phone from 'assets/images/icons/phone.svg';
import mail from 'assets/images/icons/mail.svg';

import ServiceSelect from 'view/components/content/service-select';
import Button from 'view/components/content/button';
import Header from 'view/components/content/header';
import Container from 'view/components/content/flex-container';

import { TriangleDown, SelectBox, StyledContainer, SmallHeader, Hr, Icon, ButtonBox, Name, ContactUs, Buttons, A, ButtonContent } from './styles';

export const HomePage: FunctionComponent = () => {
  const { accountManager } = useSelector((state: RootState) => state.services);
  const { offerId } = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const signature = new URLSearchParams(search).get('signature') || localStorage.getItem('signature');

    if (signature) {
      localStorage.setItem('signature', signature);
    }
    else {
      history.push(paths.errorPage)
    }
    apiService.getOffers(offerId).then(resp => {
      const setOfferAction = setOffer(resp.data.data)
      dispatch(setOfferAction);
    }).catch(() => {
      history.push(paths.errorPage)
    })
    apiService.getCampaigns(offerId).then(resp => {
      const activeTo = (resp.data.data.active_to);
      const dateNow = new Date();
      if (activeTo !== null && new Date(activeTo).getTime() < dateNow.getTime()) history.push(paths.errorPage);

    })
  }, [dispatch, offerId, search, history])

  return <div>
    <Header text={"Strona główna"}>
      <div>Wybierz swoją usługę i&nbsp;zobacz co dla Ciebie przygotowaliśmy</div>
    </Header>
    <TriangleDown />
    <Container>
      <SelectBox>
        <ServiceSelect offerId={offerId} initial />
      </SelectBox>
    </Container>
    <Hr />
    <StyledContainer>
      <div>
        <SmallHeader>Masz pytania?</SmallHeader>
        <ContactUs >Skontaktuj się z naszym przedstawicielem:</ContactUs>
        <Name>{accountManager.name}</Name>
      </div>
      <Buttons >
        <A href={`tel: ${accountManager.telephone}`}>
          <ButtonBox >
            <Button buttonStyle='white'>
              <ButtonContent>
                <Icon src={phone} /> Zadzwoń
              </ButtonContent>
            </Button>
          </ButtonBox>
        </A>
        <A href={`mailto: ${accountManager.email}`}>
          <ButtonBox>
            <Button buttonStyle='white'>
              <ButtonContent>
                <Icon src={mail} /> Napisz do nas e-mail
              </ButtonContent>
            </Button>
          </ButtonBox>
        </A>
      </Buttons>
    </StyledContainer>
  </div >;
};
