import styled from 'styled-components';

export const ActionBox = styled.div`
    background-color: ${(props) => props.theme.colors.lightGray};
    padding: 31.5px 0 62.5px 0;
    text-align: center;
    margin-top: 29px;
  `,
  ActionButtonBox = styled.div`
    margin-top: 20px;
  `,
  ArrowIcon = styled.img`
    width: 9px;
  `,
  ButtonChildren = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Header = styled.h4`
    padding-bottom: 13px;
  `;
