import styled from 'styled-components';
import Container from 'view/components/content/flex-container';

export const TriangleDown = styled.div`
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 15px solid ${(props) => props.theme.colors.darkGray};
    margin: -1px auto 0 auto;
  `,
  SelectBox = styled.div`
    margin: 28px auto 72px auto;
  `,
  StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
  `,
  SmallHeader = styled.h2`
    padding-top: 10px;
  `,
  Hr = styled.hr`
    border-top: 1px solid #000;
  `,
  Icon = styled.img`
    height: 23px;
    margin-right: 22.5px;
  `,
  ContactUs = styled.div`
    margin: 6px 0 30px 0;
  `,
  Name = styled.h4`
    color: #666666;
    margin-bottom: 23px;
  `,
  ButtonBox = styled.div`
    margin: 11.5px 0;
    max-width: 400px;
  `,
  Buttons = styled.div`
    width: 100%;
  `,
  A = styled.a`
    color: inherit;
    text-decoration: none;
  `,
  ButtonContent = styled.div`
    display: flex;
    align-items: center;
  `;
