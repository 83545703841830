import React, { FunctionComponent } from 'react';

import Header from 'view/components/content/header';

export const ErrorPage: FunctionComponent = () => {

  return <div>
    <Header text={"Błąd 404"}>
      <div>Nie znaleziono danej strony lub nie masz do niej dostępu.</div>
    </Header>
  </div >;
};
