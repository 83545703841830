import styled from 'styled-components';

export const Base = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  `,
  Main = styled.div`
    flex: 1 0 auto;
    min-height: 180px;
    height: 100%;
  `;
