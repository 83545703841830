import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';

import { apiService } from 'core/services';
import { RootState } from 'core/store/store';
import MessageModal from 'view/components/structure/message';
import Input from 'view/components/content/input';
import Button from 'view/components/content/button';
import Modal from 'view/components/content/modal';
import Warning from 'view/components/content/warning';
import closeIcon from 'assets/close.svg';
import { IForm } from 'view/pages/interfaces';

import { Main, CloseBox, Close, Header, ButtonBox, InputsBox } from './styles';

export const ContactRequestFormPage: FunctionComponent<IForm> = ({ isOpen, close }) => {
  const { offerId } = useParams();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { services, costOfAllServices } = useSelector((state: RootState) => state.services);
  const extras = useSelector((state: RootState) => state.offers.extra);

  const emailData = {
    email: '',
    payload: {
      totalPrice: costOfAllServices.toFixed(2).toString().replace('.', ','),
      services: services.map(ser => ({
        number: ser.number,
        title: ser.title,
        phone: ser.phone ? {
          price1: ser.phone.price.toFixed(2).toString().split(".")[0],
          price2: ser.phone.price.toFixed(2).toString().split(".")[1],
          name: ser.phone.name,
          imageUrl: ser.phone.imageUrl
        } : undefined,
        extras: extras.map(ext => ({
          imageUrl: ext.imageUrl,
          title: ext.title,
          isChecked: ser.selectedExtras ? ser.selectedExtras.some(x => x.id === ext.id) : false,
        })),
      }))
    }
  };

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    setIsLoading(true);
    emailData.email = data.email;
    apiService.postOfferSendOffer(offerId, emailData).then(() => {
      setIsMessageModalOpen(true);
      setIsLoading(false);
    }
    ).catch((x) => {
      setErrorMessage('Przepraszamy, wystąpił błąd.')
    });
  };

  return <Modal isModalOpen={isOpen} close={close}>
    <Main >
      <CloseBox>
        <Close src={closeIcon} onClick={() => close()} />
      </CloseBox>
      <MessageModal button="Zamknij" header="Dziękujemy!" message="Twoje zgłoszenie zostało poprawnie wysłane." isModalOpen={isMessageModalOpen} close={() => setIsMessageModalOpen(false)} />
      <Header>Wypełnij formularz, aby pobrać ofertę</Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputsBox>
          <Input labelText="Wyślij ofertę na adres e-mail" name="email" register={register({ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })} type="email" />
          {errors.email && errors.email.type === "pattern" && <Warning text="Wpisz poprawny format adresu e-mail." />}
          {errors.email && errors.email.type === "required" && <Warning text="To pole jest wymagane." />}
        </InputsBox>
        {errorMessage && <Warning text={errorMessage} />}
        {isLoading && <Warning text="Wysyłam..." />}
        <ButtonBox >
          <Button buttonStyle="orange"><div>Wyślij</div></Button>
        </ButtonBox>
      </form>
    </Main>
  </Modal>;
};
