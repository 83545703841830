import React, { FunctionComponent } from 'react';

import { Theme } from 'core/theme';
import { ISelectComponent } from '../interfaces';
import { dropdownIndicatorStyles, placeholderStyles, optionStyles, IconOption, controlStyles } from './SelectStyles';
import { StyledSelect } from './styles';

export const SelectComponent: FunctionComponent<ISelectComponent> = ({ options, onChange, defaultValue, placeholder, register, name }) => {

  return <StyledSelect
    name={name}
    ref={register}
    defaultValue={defaultValue}
    onChange={onChange}
    options={options}
    label="Single select"
    noOptionsMessage={() => "Brak opcji"}
    placeholder={placeholder ? placeholder : options[0]}
    styles={{ dropdownIndicator: dropdownIndicatorStyles, placeholder: placeholderStyles, option: optionStyles, control: controlStyles }}
    components={{
      IndicatorSeparator: () => null,
      Option: IconOption
    }}
    theme={theme => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: Theme.colors.orange,
        primary: 'black',
      },
    })} />
};