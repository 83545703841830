import React, { FunctionComponent } from 'react';

import OfferTab from 'view/components/content/offer-tab';
import { IOffers } from 'view/pages/interfaces';
import { OffersMain } from './styles'

export const Offers: FunctionComponent<IOffers> = ({ services }) => {

  return <OffersMain>
    {services.map(service => <OfferTab key={service.id} offer={service} />)}
  </OffersMain>
};
