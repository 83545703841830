import 'index.css';

export const Theme = {
  colors: {
    orange: '#F16E00',
    orangeHover: '#F38426',
    gray: '#D8D8D8',
    lightGray: 'rgba(216, 216, 216, 0.3)',
    darkGray: '#666666',
    darkGrayHover: '#666666a3',
    white: '#ffffff',
    black: '#000000',
  },
};

export type BaseTheme = typeof Theme;
