import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';

import { IModal } from '../interfaces';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%'
  }
};

export const ModalComponent: FunctionComponent<IModal> = ({ children, isModalOpen, close }) => {
  return <Modal
    isOpen={isModalOpen}
    onRequestClose={close}
    style={customStyles}
    ariaHideApp={false}>
    {children}
  </Modal>;
};

export default ModalComponent;
