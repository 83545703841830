import { IServiceState, ServiceActionTypes, SERVICES_SET_ACTIVE_SERVICE, SERVICES_TOGGLE_EXTRA, SERVICES_SELECT_PHONE } from './types';
import { OFFERS_SET_OFFER, IService, ITelephone, IExtra, IAccountManager } from '../offers/types';

const initialState: IServiceState = {
  activeServiceId: undefined,
  services: [] as IService[],
  telephones: [] as ITelephone[],
  accountManager: {} as IAccountManager,
  costOfAllServices: 0,
} as IServiceState;

const setActiveServiceChoice = (state, action) => {
  let updatedState = { ...state, activeServiceId: action.payload.service };
  if (action.payload.initial) {
    const updatedServices = [...state.services].map((x) => {
      return x.isNumber ? { ...x, phone: state.telephones[0] } : x;
    });
    const costOfAllServices = updatedServices
      .map((x) => calculateExtrasTotal(x.selectedExtras) + (x.phone === undefined ? 0 : x.phone?.price) + x.price)
      .reduce((a, b) => a + b, 0);
    updatedState = { ...updatedState, services: updatedServices, costOfAllServices };
  }
  return updatedState;
};
const setNewPhoneChoice = (state, action) => {
  const activeServiceIndex = state.services.findIndex((x) => x.id === state.activeServiceId);
  const updatedServices = [...state.services];
  updatedServices[activeServiceIndex].phone = action.payload;
  const costOfAllServices = updatedServices
    .map((x) => calculateExtrasTotal(x.selectedExtras) + (x.phone === undefined ? 0 : x.phone?.price))
    .reduce((a, b) => a + b, 0);
  return { ...state, services: updatedServices, costOfAllServices };
};

const calculateExtrasTotal = (extras = [] as IExtra[]) => extras.map((x) => x.price).reduce((a, b) => a + b, 0);
const serviceReducer = (state: IServiceState = initialState, action: ServiceActionTypes): IServiceState => {
  switch (action.type) {
    case OFFERS_SET_OFFER:
      return {
        ...state,
        services: action.payload.services,
        telephones: action.payload.telephones,
        accountManager: action.payload.accountManager,
      };
    case SERVICES_SET_ACTIVE_SERVICE:
      return setActiveServiceChoice(state, action);

    case SERVICES_TOGGLE_EXTRA:
      const activeServiceIndex = state.services.findIndex((x) => x.id === state.activeServiceId);
      const updatedServices = [...state.services];
      const activeServiceExtras =
        updatedServices[activeServiceIndex].selectedExtras === undefined
          ? []
          : (updatedServices[activeServiceIndex].selectedExtras as IExtra[]);
      const extraIdx = activeServiceExtras.findIndex((x) => x.id === action.payload.id);
      if (extraIdx === -1) {
        activeServiceExtras.push(action.payload);
        updatedServices[activeServiceIndex].selectedExtras = activeServiceExtras;
      } else {
        const oldExtras = [...activeServiceExtras];
        oldExtras.splice(extraIdx, 1);
        updatedServices[activeServiceIndex].selectedExtras = oldExtras;
      }

      updatedServices[activeServiceIndex].extrasTotalPrice = calculateExtrasTotal(updatedServices[activeServiceIndex].selectedExtras);

      const costOfAllServices = state.services
        .map((x) => calculateExtrasTotal(x.selectedExtras) + (x.phone === undefined ? 0 : x.phone?.price))
        .reduce((a, b) => a + b, 0);
      return {
        ...state,
        services: updatedServices,
        costOfAllServices: costOfAllServices,
      };

    case SERVICES_SELECT_PHONE:
      return setNewPhoneChoice(state, action);
    default:
      return state;
  }
};

export default serviceReducer;
