import styled from 'styled-components';
import Select from 'react-select';

import { Theme } from 'core/theme';

export const StyledSelect = styled(Select)`
    border: 2px solid ${Theme.colors.darkGray};
    font-family: 'Helvetica';
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;

    &:focus {
      border: 2px solid ${Theme.colors.darkGray} !important;
    }
  `,
  OptionComponent = styled.div`
    display: flex;
    align-items: center;
    margin: auto 12px;
    justify-content: space-between;
  `,
  Dot = styled.div`
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.orange};
    margin-right: 16px;
  `,
  DotLabelBox = styled.div`
    display: flex;
    align-items: center;
  `,
  ArrowIcon = styled.img`
    width: 10px;
  `;
