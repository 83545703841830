import React, { FunctionComponent, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from 'core/store/store';
import Select from 'view/components/content/select';
import { setActiveService } from 'features/services/actions';
import { IServiceSelect } from 'view/components/content/interfaces';

export const ServiceSelect: FunctionComponent<IServiceSelect> = ({ offerId, defaultValue, initial }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { services, activeServiceId } = useSelector((state: RootState) => state.services);
  const activeName = services.filter(x => x.id === activeServiceId)[0];
  const options = useMemo(() => services.map(x => ({ label: x.isNumber ? x.number : x.name, value: x.id })), [services]);
  const placeholder = activeServiceId ? (activeName.isNumber ? activeName.number : activeName.name) : "Wybierz usługę";

  const onUserChange = (value) => {
    const action = setActiveService(value.value, initial);
    dispatch(action);
    history.push(`/offer/${offerId}/details`);
  }

  return <Select options={options} onChange={onUserChange} placeholder={placeholder} />
};
