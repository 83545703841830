export interface IAccountManager {
  name: string;
  email: string;
  telephone: string;
}
interface ICampaign {
  id: number;
  name: string;
  availableContactHours: string[];
}
export interface IExtra {
  id: string;
  name: string;
  title: string;
  imageUrl: string;
  price: number;
  isChecked?: boolean;
}
export interface ITelephone {
  id: string;
  name: string;
  title: string;
  imageUrl: string;
  price: number;
}
export interface IService {
  id: number;
  isNumber: boolean;
  number: string;
  price: number;
  name: string | null;
  title: string;
  selectedExtras?: IExtra[];
  extrasTotalPrice?: number;
  phone?: ITelephone;
}
export interface IOffer {
  accountManager: IAccountManager;
  campaign: ICampaign;
  extra: IExtra[];
  globalId: string;
  id: number;
  isActive: boolean;
  services: IService[];
  telephones: ITelephone[];
}
export interface IOffersState {
  accountManager: IAccountManager;
  campaign: ICampaign;
  extra: IExtra[];
  globalId: string;
  id: number;
  isActive: boolean;
}

export const OFFERS_SET_OFFER = 'OFFERS_SET_OFFER';

export interface SetOfferAction {
  type: typeof OFFERS_SET_OFFER;
  payload: IOffer;
}

export type OffersActionTypes = SetOfferAction;
