import React, { FunctionComponent } from 'react';

import { IInputProps } from '../interfaces';
import { Text, StyledInput } from './styles';

export const InputComponent: FunctionComponent<IInputProps> = ({ labelText, register, name, type }) => (
  <div>
    <Text>{labelText}</Text>
    <StyledInput ref={register} name={name} type={type} />
  </div>
)