import React, { FunctionComponent, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';

import { RootState } from 'core/store/store';
import Service from 'view/components/content/service';
import ServiceSelect from 'view/components/content/service-select';
import Header from 'view/components/content/header';
import Offers from './components/offers';
import Container from 'view/components/content/flex-container';
import ActionBox from 'view/components/content/action-box';
import { toggleExtra } from 'features/services/actions';
import { IService, IExtra } from 'features/offers/types';

import Summary from './components/summary';
import SmartphoneOffer from './components/smartphone-offer';
import ContactGetOfferForm from 'view/pages/contact-get-offer-form';
import ContactRequestForm from 'view/pages/contact-request-form';
import ContactForm from 'view/pages/contact-form';

import { Heading, Number, Offer, ServicesHeader, ServiceBox } from './styles'

export const OfferPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { offerId } = useParams();

  const offersState = useSelector((state: RootState) => state.offers);
  const { activeServiceId, services } = useSelector((state: RootState) => state.services);
  const activeService: IService = useMemo(() => services.find(x => x.id === activeServiceId), [services, activeServiceId])
  const [getOfferFormOpen, setGetOfferFormOpen] = useState<boolean>(false);
  const [contactRequestFormOpen, setContactRequestFormOpen] = useState<boolean>(false);
  const [contactFormOpen, setContactFormOpen] = useState<boolean>(false);
  const mainPrice = activeService.price ? activeService.price : 0;

  const onCheckboxClick = (extra: IExtra) => {
    const action = toggleExtra(extra);
    dispatch(action);
  }
  const onBreadcrumbClick = () => {
    window.location.href = (`/offer/${offerId}`)
  }
  if (activeService === undefined) onBreadcrumbClick();

  return <div>
    <ContactGetOfferForm isOpen={getOfferFormOpen} close={() => setGetOfferFormOpen(false)} />
    <ContactRequestForm isOpen={contactRequestFormOpen} close={() => setContactRequestFormOpen(false)} />
    <ContactForm isOpen={contactFormOpen} close={() => setContactFormOpen(false)} />
    <Header text="< powrót" onBreadcrumbsClick={onBreadcrumbClick}>
      <ServiceSelect offerId={offerId} initial={false} />
    </Header>
    <Container>
      <Heading>Dla usługi</Heading>
      <Number>{activeService && (activeService.isNumber ? activeService.number : activeService.name)}</Number>
      <hr />
      <h2>proponujemy:</h2>
      <Offer>{activeService && parse(activeService.title)}</Offer>
      {activeService.isNumber && <>
        <SmartphoneOffer smartphones={offersState.telephones} activePhoneId={activeService && activeService.phone?.id} isPhoneSelected={activeService.phone !== undefined} />
        <ServiceBox>
          <ServicesHeader>Usługi dodatkowe</ServicesHeader>
          {offersState.extra && offersState.extra.map((extra) =>
            <Service
              key={extra.id}
              text={extra.title}
              image={extra.imageUrl}
              onCheckboxClick={() => onCheckboxClick(extra)}
              checked={activeService.selectedExtras && activeService.selectedExtras?.findIndex(x => x.id === extra.id) > -1} />)}
        </ServiceBox>
      </>
      }
    </Container>
    <Summary mainPrice={mainPrice} extrasTotalPrice={(activeService.extrasTotalPrice === undefined) ? 0 : activeService.extrasTotalPrice} smartphonePrice={activeService.phone?.price} />
    <Container>
      <Offers services={services} />
      <h4>Aby potwierdzić wybór oferty i&nbsp;zamówić wybrane usługi wybierz formę kontaktu z&nbsp;naszym&nbsp;przedstawicielem.</h4>
    </Container>
    <ActionBox onGetOfferClick={() => setGetOfferFormOpen(true)}
      onContactRequestClick={() => setContactRequestFormOpen(true)}
      onContactFormClick={() => setContactFormOpen(true)} />
  </div >;
};
