import React, { FunctionComponent } from 'react';
import parse from 'html-react-parser';
import { IOfferTab } from 'view/components/content/interfaces';

import { StyledTab, Header, LeftSide, LeftSideMain, RightSide, TriangleDown, Category, AdditionalInformation, Service, ServiceBox, Image } from './styles';

export const OfferTabComponent: FunctionComponent<IOfferTab> = ({ offer }) => {

  const { number, selectedExtras, phone, isNumber, name, title, extrasTotalPrice, price } = offer;
  const vat = '+ VAT/mies.';
  const extrasPrice = extrasTotalPrice ? extrasTotalPrice : 0;
  const phonePrice = phone ? phone.price : 0;
  const ifServicesVisible = phone !== undefined || selectedExtras !== undefined || title !== undefined;
  const mainPrice = price ? price : 0;

  return <StyledTab>
    <Header>
      <LeftSideMain>
        <Category>Usługa</Category>
        <h2>{isNumber ? number : name}</h2>
      </LeftSideMain>
      <RightSide>
        <Category>Opłaty</Category>
        <h2>{(phonePrice + extrasPrice + mainPrice).toFixed(2).toString().replace('.', ',')} zł</h2>
        <AdditionalInformation>{vat}</AdditionalInformation>
      </RightSide>
    </Header>
    {ifServicesVisible && <TriangleDown />}
    {title !== undefined &&
      <ServiceBox>
        <Service last={selectedExtras === undefined && phone === undefined}>
          <LeftSide>
            <div>{parse(title)}</div>
          </LeftSide>
          <RightSide>
            <h2>{mainPrice.toFixed(2).toString().replace('.', ',')} zł</h2>
            <AdditionalInformation>{vat}</AdditionalInformation>
          </RightSide>
        </Service>
      </ServiceBox>
    }
    <ServiceBox>
      {phone !== undefined &&
        <Service last={selectedExtras === undefined || selectedExtras.length < 1}>
          <LeftSide>
            {phone.imageUrl && <Image alt="" src={phone.imageUrl} />}
            <div>{parse(phone.name)}</div>
          </LeftSide>
          <RightSide>
            <h2>{phone.price.toFixed(2).toString().replace('.', ',')} zł</h2>
            <AdditionalInformation>{vat}</AdditionalInformation>
          </RightSide>
        </Service>
      }
    </ServiceBox>
    <ServiceBox>
      {selectedExtras !== undefined && selectedExtras.map((extra, idx) =>
        <Service key={extra.id} last={idx + 1 === selectedExtras.length}>
          <LeftSide>
            <div>{parse(extra.name)}</div>
          </LeftSide>
          <RightSide>
            <h2>{extra.price.toFixed(2).toString().replace('.', ',')} zł</h2>
            <AdditionalInformation>{vat}</AdditionalInformation>
          </RightSide>
        </Service>
      )}
    </ServiceBox>
  </StyledTab >
};