import Axios from 'axios';
import { ApiService, IGetOffersResponse, IGetCampaignResponse, IBodyForm, IBodyTelephoneContact, IEmail } from './interfaces';

const getCampaigns = (offerId: number) => Axios.get<any, IGetCampaignResponse>(`${process.env.REACT_APP_API_BASE_URL}campaigns/${offerId}`);
const getOffers = (offerId: number) =>
  Axios.get<any, IGetOffersResponse>(`${process.env.REACT_APP_API_BASE_URL}campaigns/offers/${offerId}`);
const postContactForm = (offerId: number, body: IBodyForm) =>
  Axios.post(`${process.env.REACT_APP_API_BASE_URL}campaigns/offers/${offerId}/contact-form`, body);
const postRequestTelephoneContact = (offerId: number, body: IBodyTelephoneContact) =>
  Axios.post(`${process.env.REACT_APP_API_BASE_URL}campaigns/offers/${offerId}/request-contact`, body);
const postOfferSendOffer = (offerId: number, body: IEmail) =>
  Axios.post(`${process.env.REACT_APP_API_BASE_URL}campaigns/offers/${offerId}/send-offer`, body);

const apiService: ApiService = {
  getCampaigns,
  getOffers,
  postContactForm,
  postRequestTelephoneContact,
  postOfferSendOffer,
};

export { apiService };
