import styled from 'styled-components';

export const Text = styled.div`
    font-size: 12px;
    color: ${(props) => props.theme.colors.darkGray};
    margin: 6.5px 0;
  `,
  StyledInput = styled.input`
    border: 2px solid ${(props) => props.theme.colors.darkGray};
    color: ${(props) => props.theme.colors.black};
    border-radius: 0;
    font-size: 14px;
    width: 100%;
    height: 47px;
    padding-left: 18px;
  `;
