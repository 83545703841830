import styled from 'styled-components';

export const Heading = styled.h1`
    padding: 32px 0;
  `,
  Number = styled.h1`
    color: ${(props) => props.theme.colors.orange};
  `,
  Offer = styled.h3`
    margin: 14px 0 36px 0;
    font-size: 15px;
  `,
  ServicesHeader = styled.h1`
    margin-bottom: 10px;
  `,
  ServiceBox = styled.div`
    margin-top: 52px;
  `;
