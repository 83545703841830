import styled from 'styled-components';
import { IServiceProps } from '../interfaces';

export const Service = styled.div<IServiceProps>`
    border-top: 1px solid #000;
    cursor: pointer;
    padding: 21px 0;
    ${(props) =>
      !props.checked &&
      `
  filter: grayscale(1);
  opacity: 0.6;
  `}
  `,
  Informations = styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Text = styled.h3`
    width: 78%;
    padding-top: 10px;
  `,
  Image = styled.img`
    height: 33px;
  `;
