import { IRoute } from './interface';

import Home from 'view/pages/home';
import Offer from 'view/pages/offer';
import Error from 'view/pages/error';

const homepagePath = '/offer/:offerId';
const offerPagePath = `${homepagePath}/details`;
const errorPagePath = `/not-found`;

export const paths = {
  homepage: homepagePath,
  offerPage: offerPagePath,
  errorPage: errorPagePath,
};

const homePage: IRoute = {
  id: 'home',
  name: 'Strona Główna',
  slug: homepagePath,
  component: Home,
};
const offerPage: IRoute = {
  id: 'offer',
  name: 'Oferta',
  slug: offerPagePath,
  component: Offer,
};
const errorPage: IRoute = {
  id: 'not-found',
  name: 'Nie znaleziono strony',
  slug: errorPagePath,
  component: Error,
};

export const routes = {
  homePage,
  offerPage,
  errorPage,
};
export type IRoutes = typeof routes;
export const routesArray: IRoute[] = Object.entries(routes).map(([_, v]) => v);
