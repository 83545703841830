import styled from 'styled-components';

export const MessageMain = styled.div`
    width: 88%;
    height: 100%;
    max-width: 700px;
    margin: auto;
    padding: 80px 0 41px 0;
    text-align: center;
  `,
  ButtonBox = styled.div`
    width: 160px;
    margin: 0 auto;
    padding-top: 40px;
  `;
