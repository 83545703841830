import styled from 'styled-components';

export const Header = styled.div`
    background-color: ${(props) => props.theme.colors.darkGray};
    width: 100%;
    height: auto;
    min-height: 100px;
    color: ${(props) => props.theme.colors.white};
    font-weight: 500;
    font-size: 25px;
    padding: 15px 0 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Breadcrumbs = styled.div`
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 25px;
    cursor: pointer;
  `;
