import styled from 'styled-components';

export const Text = styled.div`
    font-size: 12px;
    color: ${(props) => props.theme.colors.darkGray};
    margin: 6.5px 0;
  `,
  TextArea = styled.textarea`
    border: 2px solid ${(props) => props.theme.colors.darkGray};
    color: ${(props) => props.theme.colors.black};
    font-size: 14px;
    width: 100%;
    padding: 12px;
    line-height: 17px;
  `;
