import React, { FunctionComponent } from 'react';
import { RootState } from 'core/store/store';
import { useSelector } from 'react-redux';

import { ISummary } from 'view/pages/offer/interfaces';
import { Main, Price, PricingSummaryBox, Pricing, SummaryBox, TriangleDown, Sentence, Plus } from './styles'

export const Summary: FunctionComponent<ISummary> = ({ extrasTotalPrice, smartphonePrice, mainPrice }) => {

  const { costOfAllServices } = useSelector((state: RootState) => state.services);

  return <Main>
    <PricingSummaryBox>
      <Sentence>Opłata za powyższe usługi:</Sentence>
      <Pricing>
        <Price>{(extrasTotalPrice + mainPrice).toFixed(2).toString().replace('.', ',')} zł + VAT/mies.</Price>
        {smartphonePrice !== undefined && <><Plus >+</Plus>
          <Price >smartfon za {smartphonePrice.toFixed(2).toString().replace('.', ',')} zł + VAT/mies.</Price></>}
      </Pricing>
    </PricingSummaryBox>
    <SummaryBox>
      <Sentence >Opłata za wszystkie usługi:</Sentence>
      <Price>{costOfAllServices.toFixed(2).toString().replace('.', ',')} zł + VAT/mies.</Price>
    </SummaryBox>
    <TriangleDown />
  </Main>
};
