import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

html {
  font-weight: 400;
  font-size: 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

html, body {
  height:100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html, body, input, textarea, button {
  font-family: 'Helvetica';
}
button {
  font-weight: 500;
}
input {
  font-weight: 400;
}
h1, h2, h3, h4, h5 {
  margin-block-start: 0;
  margin-block-end: 0;
}
h1{
  font-size: 24px;
  font-weight: 600;
}
h2{
  font-size: 18px;
  font-weight: 500;
}
h3{
  font-size: 18px;
  font-weight: 400;
}
h4{
  font-size: 16px;
  font-weight: 500;
}
h5{
  font-size: 16px;
  font-weight: 400;
}
`;

export default GlobalStyle;
