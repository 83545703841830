import React, { FunctionComponent } from 'react';

import Button from 'view/components/content/button';
import Container from 'view/components/content/flex-container';
import arrow from 'assets/images/icons/arrow-right-select.svg';
import { IActionBox } from 'view/components/content/interfaces';

import { ActionBox, ActionButtonBox, ArrowIcon, ButtonChildren, Header } from './styles'

export const ActionBoxComponent: FunctionComponent<IActionBox> = ({ onGetOfferClick, onContactRequestClick, onContactFormClick }) => {

  const buttonsValues = [
    {
      name: 'Pobierz ofertę',
      onClick: onGetOfferClick,
    },
    {
      name: 'Proszę o kontakt telefoniczny',
      onClick: onContactRequestClick,
    },
    {
      name: 'Kontakt z infolinią',
      onClick: onContactFormClick,
    }];

  return <ActionBox>
    <Container>
      <Header >Jestem zainteresowana/y tą ofertą</Header>
      {buttonsValues.map((value, idx) =>
        <ActionButtonBox key={`button-${idx}`}>
          <Button buttonStyle="white" onClick={value.onClick}>
            <ButtonChildren>
              {value.name}
              <ArrowIcon src={arrow} alt="" />
            </ButtonChildren>
          </Button>
        </ActionButtonBox>
      )}
    </Container>
  </ActionBox>
};