import { IExtra, ITelephone, SetOfferAction, IService, IAccountManager } from './../offers/types';

export interface IServiceState {
  services: IService[];
  activeServiceId?: number;
  telephones: ITelephone[];
  accountManager: IAccountManager;
  costOfAllServices: number;
}

export const SERVICES_SET_ACTIVE_SERVICE = 'SERVICES_SET_ACTIVE_SERVICE';
export const SERVICES_TOGGLE_EXTRA = 'SERVICES_TOGGLE_EXTRA';
export const SERVICES_SELECT_PHONE = 'SERVICES_SELECT_PHONE';

interface SetActiveServiceAction {
  type: typeof SERVICES_SET_ACTIVE_SERVICE;
  payload: { service: number | undefined; initial: boolean };
}
interface ToggleExtraAction {
  type: typeof SERVICES_TOGGLE_EXTRA;
  payload: IExtra;
}
interface SelectPhoneAction {
  type: typeof SERVICES_SELECT_PHONE;
  payload: ITelephone | undefined;
}

export type ServiceActionTypes = SetActiveServiceAction | ToggleExtraAction | SetOfferAction | SelectPhoneAction;
