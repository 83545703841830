import React, { FunctionComponent } from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";

import { routesArray } from 'core/router/routes';
import { IRoute } from 'core/router/interface';
import { Base, Main } from './styles';


export const getRoutersToRender = (list: (IRoute | 'divider')[]) => {
  let flattenList: IRoute[] = [];
  list.forEach((item: IRoute | 'divider') => {
    if (item === 'divider') {
      return;
    }

    if (item.component !== undefined) {
      flattenList.push(item);
    }

    if (item.children !== undefined && item.children.length > 0) {
      const subRoutes = item.children.map((children: IRoute | 'divider') => {
        let newPath;
        if (children !== 'divider') {
          newPath = `${item.slug}${children.slug}`
        }
        return Object.assign({}, children, { slug: newPath });
      });
      flattenList = flattenList.concat(...getRoutersToRender(subRoutes));
    }
  });

  return flattenList;
};

export const BaseLayout: FunctionComponent = () => {

  return <Base>
    <Main>
      <Switch>
        {getRoutersToRender(routesArray).map((route: IRoute, idx: number) => {
          return <Route key={idx} exact path={route.slug} component={route.component} />;
        })}
      </Switch>
    </Main>
  </Base>;
};
