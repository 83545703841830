import { IOffersState, OffersActionTypes, OFFERS_SET_OFFER } from './types';

// contains all data from the API (should not be change by the user)
const initialState: IOffersState = {} as IOffersState;

const offersReducer = (state: IOffersState = initialState, action: OffersActionTypes): IOffersState => {
  switch (action.type) {
    case OFFERS_SET_OFFER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default offersReducer;
