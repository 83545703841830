import { IExtra, ITelephone } from './../offers/types';
import { ServiceActionTypes, SERVICES_SET_ACTIVE_SERVICE, SERVICES_TOGGLE_EXTRA, SERVICES_SELECT_PHONE } from './types';

export const setActiveService = (service: number | undefined, initial: boolean): ServiceActionTypes => {
  return {
    type: SERVICES_SET_ACTIVE_SERVICE,
    payload: { service, initial },
  };
};

export const toggleExtra = (extra: IExtra): ServiceActionTypes => {
  return {
    type: SERVICES_TOGGLE_EXTRA,
    payload: extra,
  };
};

export const selectPhone = (phone: ITelephone | undefined): ServiceActionTypes => {
  return {
    type: SERVICES_SELECT_PHONE,
    payload: phone,
  };
};
