import React, { FunctionComponent } from 'react';
import parse from 'html-react-parser';

import Checkbox from 'view/components/content/checkbox';
import { IServiceProps } from '../interfaces';

import { Service, Informations, Text, Image } from './styles';

export const ServiceComponent: FunctionComponent<IServiceProps> = ({ image, text, onCheckboxClick, checked }) => {
  const parsedText = parse(text);

  return <Service checked={checked} onClick={onCheckboxClick}>
    <Image src={image} alt="" />
    <Informations>
      <Text>{parsedText}</Text>
      <Checkbox id="service-checkbox" scale={1.2} checked={checked} />
    </Informations>
  </Service>
};