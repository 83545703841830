import styled from 'styled-components';
import { IServiceStyles } from 'view/components/content/interfaces';

export const StyledTab = styled.div`
    background-color: ${(props) => props.theme.colors.lightGray};
    border: 1px solid ${(props) => props.theme.colors.darkGray};
    margin-bottom: 36.25px;

    h2 {
      padding: 0;
    }
  `,
  Header = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    padding: 19.5px 18.5px;
  `,
  LeftSideMain = styled.div`
    width: 45%;
  `,
  LeftSide = styled.div`
    display: flex;
    width: 45%;
  `,
  RightSide = styled.div`
    width: 40%;
    margin-left: auto;
  `,
  TriangleDown = styled.div`
    width: 0;
    height: 0;
    border-left: 27px solid transparent;
    border-right: 27px solid transparent;

    border-top: 18px solid ${(props) => props.theme.colors.white};
    margin: -1px auto 0 auto;
  `,
  Category = styled.div`
    color: ${(props) => props.theme.colors.orange};
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 5px;
  `,
  AdditionalInformation = styled.div`
    padding-top: 4px;
  `,
  ServiceBox = styled.div`
    padding: 0 18.5px;
  `,
  Service = styled.div<IServiceStyles>`
    border-bottom: 1px solid ${(props) => props.theme.colors.darkGray};
    display: flex;
    padding: 19.5px 0;
    line-height: 17px;
    ${(props) => props.last && 'border-bottom: 0'};
  `,
  Image = styled.img`
    width: 44px;
    padding-right: 12.5px;
  `;
