import React, { FunctionComponent, Fragment } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components';

import { store } from './core/store'
import { Theme } from './core/theme';
import GlobalStyle from './core/theme/global-style';
import { BaseLayout } from './view/layout';

const App: FunctionComponent = () => {

  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <Fragment>
          <GlobalStyle />
          <Router>
            <BaseLayout />
          </Router>
        </Fragment>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
