import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { RootState } from 'core/store/store';
import { selectPhone } from 'features/services/actions';
import { ISmartphoneOffer } from 'view/components/content/interfaces';
import arrowRight from 'assets/images/icons/big-arrow-right.svg';
import arrowLeft from 'assets/images/icons/big-arrow-left.svg';

import Button from 'view/components/content/button';
import Checkbox from 'view/components/content/checkbox';

import {
  ChooseDevice, DeviceBox, PriceCircle, PriceContent, PriceHeader, PriceBox, Price, Currency, VATinfo, Sentence, ChooseSmartphone, ButtonBox,
  ArrowIcon, CheckboxText, CircleBox, ChoiceBox, GraphicsBox, PhoneImageBox, SmartphoneImage, NumberOfItem, Box
} from './styles'

export const SmartphoneOfferComponent: FunctionComponent<ISmartphoneOffer> = ({ smartphones, activePhoneId, isPhoneSelected }) => {
  const dispatch = useDispatch();
  const { activeServiceId } = useSelector((state: RootState) => state.services);
  const [disableClick, setDisableClick] = useState(false);
  const [activeId, setActiveId] = useState(0);
  const rightNotActive = activeId + 1 === smartphones.length;
  const isActive = smartphones[activeId].id === activePhoneId;
  const smartphonePrice = (smartphones[activeId].price).toFixed(2).toString().split(".");

  const onUserChange = () => {
    const action = selectPhone(smartphones[activeId]);
    dispatch(action);
  }
  const onCheckboxClick = () => {
    if (activePhoneId !== undefined) {
      const action = selectPhone(undefined);
      dispatch(action);
    }
    else {
      const action = selectPhone(smartphones[0]);
      dispatch(action);
    }
    setDisableClick(!disableClick);
  }

  useEffect(() => {
    setActiveId(0);
    setDisableClick(!isPhoneSelected);
  }, [activeServiceId, isPhoneSelected]);

  return <div>
    <ChooseDevice disabledClick={disableClick}>
      <h1>Dobierz smartfon w&nbsp;supercenie</h1>
      <DeviceBox >
        <GraphicsBox >
          <CircleBox >
            <PriceCircle>
              <PriceContent>
                <PriceHeader>za</PriceHeader>
                <PriceBox>
                  <Price>{smartphonePrice[0]}</Price>
                  <Currency>
                    <h1>{smartphonePrice[1] !== '00' && smartphonePrice[1]}</h1>
                    <h1>&nbsp;zł</h1>
                  </Currency>
                </PriceBox>
                <VATinfo>+ VAT/mies.</VATinfo>
              </PriceContent>
            </PriceCircle>
          </CircleBox>
          <Sentence>{smartphones[activeId].title && parse(smartphones[activeId].title)}</Sentence>
        </GraphicsBox>
        <PhoneImageBox >
          <SmartphoneImage alt="" src={smartphones[activeId].imageUrl} />
        </PhoneImageBox>
      </DeviceBox>
      <ChooseSmartphone>
        <h4>{smartphones[activeId].name}</h4>
        <ButtonBox>
          <ArrowIcon alt="" src={arrowLeft} side={'left'} onClick={() => { if (activeId > 0) setActiveId(activeId - 1) }} notActive={activeId === 0} />
          <Button buttonStyle={isActive ? 'disabled' : 'orange'} onClick={onUserChange}>
            <h4 >{isActive ? 'Wybrany' : 'Wybieram ten smartfon'}</h4>
          </Button>
          <ArrowIcon alt="" src={arrowRight} side={'right'} onClick={() => { if (activeId < smartphones.length - 1) setActiveId(activeId + 1) }} notActive={rightNotActive} />
        </ButtonBox>
        <NumberOfItem >{activeId + 1}/{smartphones.length}</NumberOfItem>
      </ChooseSmartphone>
    </ChooseDevice>
    <ChoiceBox onClick={onCheckboxClick}>
      <Box>
        <Checkbox id="smartphone-checkbox" scale={1.2} checked={!isPhoneSelected} onCheckboxClick={() => { }} />
      </Box>
      <CheckboxText>Nie jestem zainteresowana/y wyborem&nbsp;smartfona</CheckboxText>
    </ChoiceBox>
  </div>
};
