import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import offersReducer from 'features/offers/offersReducer';
import serviceReducer from 'features/services/serviceReducer';

const rootReducer = combineReducers({
  offers: offersReducer,
  services: serviceReducer,
});

const store = createStore(rootReducer, devToolsEnhancer({}));

export default store;

export type RootState = ReturnType<typeof rootReducer>;
