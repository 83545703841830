import styled from 'styled-components';

export const Main = styled.div`
    width: 88%;
    height: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 15px 0 41px 0;

    p {
      line-height: 23px;
    }
  `,
  CloseBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 34.5px;
  `,
  Close = styled.img`
    width: 16px;
    cursor: pointer;
  `,
  ButtonBox = styled.div`
    max-width: 170px;
    margin: 0 auto;
    margin-top: 24px;
  `,
  Header = styled.h1`
    text-align: center;
  `,
  InputsBox = styled.div`
    margin-top: 21px;
  `,
  SelectBox = styled.div`
    margin-top: 24px;
    margin-bottom: 28px;
  `,
  Action = styled.div`
    font-size: 12px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  `,
  CheckboxText = styled.div`
    margin-left: 22px;
    margin-top: -4px;
    width: 77%;
  `,
  CheckboxBox = styled.div`
    display: flex;
    margin-left: auto;
  `,
  ConsentText = styled.div`
    font-size: 12px;
    line-height: 19px;
    padding-top: 23px;
  `;
