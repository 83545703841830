import React, { FunctionComponent } from 'react';

import Container from 'view/components/content/flex-container';
import { IHeaderProps } from '../interfaces';

import { Header, Breadcrumbs } from './styles';

export const HeaderComponent: FunctionComponent<IHeaderProps> = ({ text, children, onBreadcrumbsClick }) => (
  <Header>
    <Container>
      <Breadcrumbs onClick={onBreadcrumbsClick}>
        {text}
      </Breadcrumbs>
      {children}
    </Container>
  </Header>
)