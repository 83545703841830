import React, { FunctionComponent, useState } from 'react';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom'
import { apiService } from 'core/services'
import Input from 'view/components/content/input';
import TextArea from 'view/components/content/text-area';
import ButtonForm from 'view/components/content/button-form';
import MessageModal from 'view/components/structure/message';
import Modal from 'view/components/content/modal';
import Warning from 'view/components/content/warning';
import arrow from 'assets/images/icons/arrow-right-select.svg';
import { IForm } from 'view/pages/interfaces';

import { Main, ButtonBox, Breadcrumbs, ArrowIcon } from './styles';

export const ContactFormPage: FunctionComponent<IForm> = ({ isOpen, close }) => {
  const [messageModalOpen, setMessageModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { offerId } = useParams();

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    setIsLoading(true);
    apiService.postContactForm(offerId, data).then(() => {
      setMessageModalOpen(true);
      setIsLoading(false);
    });
  };

  return <Modal isModalOpen={isOpen} close={close}>
    <Main >
      <MessageModal button="Zamknij okno" header="Dziękujemy!" message="E-mail został wysłany poprawnie." isModalOpen={messageModalOpen} close={() => setMessageModalOpen(false)} />
      <Breadcrumbs onClick={() => close()}>
        <ArrowIcon src={arrow} />
       powrót
  </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input name="name" register={register({ required: true })} labelText="Imię i nazwisko" />
        {errors.name && errors.name.type === "required" && <Warning text="To pole jest wymagane." />}
        <Input name="email" register={register({ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })} labelText="Twój adres e-mail" type="email" />
        {errors.email && errors.email.type === "required" && <Warning text="To pole jest wymagane." />}
        {errors.email && errors.email.type === "pattern" && <Warning text="Wpisz poprawny format adresu e-mail." />}
        <TextArea name="message" register={register({ required: true, minLength: 10 })} labelText="Napisz wiadomość" />
        {errors.message && errors.message.type === "minLength" && <Warning text="Pole musi mieć przynajmniej 10 znaków." />}
        {errors.message && errors.message.type === "required" && <Warning text="To pole jest wymagane." />}
        {isLoading && <Warning text="Wysyłam..." />}
        <ButtonBox >
          <ButtonForm buttonStyle="orange" placeholderText="Wyślij" />
        </ButtonBox>
      </form>
    </Main >
  </Modal>;
};
