import styled from 'styled-components';

import { IButtonProps } from '../interfaces';

export const StyledButton = styled.input<IButtonProps>`
  cursor: pointer;
  width: 100%;
  min-width: 170px;
  height: 50px;
  font-size: 16px;
  padding: 0 18px;
  transition: all 0.2s;
  border: none;

  &:focus {
    outline: none;
  }
  ${(props) =>
    props.buttonStyle === 'orange' &&
    `
  background-color: ${props.theme.colors.orange};
  color: ${props.theme.colors.white}; 
  &:hover {
    background-color: ${props.theme.colors.orangeHover};
  }`}
  ${(props) =>
    props.buttonStyle === 'white' &&
    `
  text-align: start;
  background-color: ${props.theme.colors.white};
  color: ${props.theme.colors.darkGray};
  box-shadow: 0px 0px 7px  ${props.theme.colors.darkGrayHover};
  &:hover {
    background-color: ${props.theme.colors.white};
    color: ${props.theme.colors.darkGrayHover};
  }`}
  ${(props) =>
    props.buttonStyle === 'disabled' &&
    `
    cursor: default;
    filter: grayscale(1);
    background-color: ${props.theme.colors.orange};
    color: ${props.theme.colors.white}; 
    &:hover {
      background-color: ${props.theme.colors.orange};
  }`}
`;
