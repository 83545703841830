import React from 'react';
import { components } from 'react-select';

import { Theme } from 'core/theme';
import arrow from 'assets/images/icons/arrow-right-select.svg';
import { OptionComponent, Dot, DotLabelBox, ArrowIcon } from './styles';

const { Option } = components;
export const IconOption = props => {
  return <Option {...props}>
    <OptionComponent>
      <DotLabelBox>
        <Dot />
        {props.data.label}
      </DotLabelBox>
      <ArrowIcon src={arrow} />
    </OptionComponent>
  </Option>
};

export const controlStyles = (base, state) => {
  const changes = {
    boxShadow: 'none',
    borderColor: 'inherit',
  };
  return Object.assign(base, changes);
};
export const optionStyles = (base, state) => {
  const changes = {
    backgroundColor: state.isSelected || state.isFocused ? '#fff' : '#fff',
    color: '#000',
    fontSize: '16px',
    lineHeight: '35px',
    fontFamily: 'Helvetica',
    fontWeight: 400,
    cursor: 'pointer',
    "&:hover": {
      color: "#000",
      backgroundColor: "rgba(216, 216, 216, 0.3)"
    }
  };
  return Object.assign(base, changes);
};
export const placeholderStyles = (base, state) => {
  const changes = {
    color: '#000'
  };
  return Object.assign(base, changes);
};
export const dropdownIndicatorStyles = (base, state) => {
  const changes = {
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    color: Theme.colors.orange,
  };
  return Object.assign(base, changes);
};