import React, { FunctionComponent } from 'react';

import { IInputProps } from '../interfaces';
import { Text, TextArea } from './styles';

export const TextAreaComponent: FunctionComponent<IInputProps> = ({ placeholder, register, name, labelText }) => (
  <div>
    <Text>{labelText}</Text>
    <TextArea rows={12} placeholder={placeholder} ref={register} name={name} />
  </div>
) 