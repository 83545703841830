import styled from 'styled-components';

export const Main = styled.div`
    width: 88%;
    height: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 15px 0 41px 0;
  `,
  CloseBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 34.5px;
  `,
  Close = styled.img`
    width: 16px;
    cursor: pointer;
  `,
  Header = styled.h1`
    text-align: center;
  `,
  ButtonBox = styled.div`
    max-width: 170px;
    margin: 0 auto;
    margin-top: 24px;
  `,
  InputsBox = styled.div`
    margin-top: 21px;
  `;
