import styled from 'styled-components';
import { IChooseDevice, IArrowIcon } from 'view/components/content/interfaces';

export const ChooseDevice = styled.div<IChooseDevice>`
    transition-duration: 0.4s;
    ${(props) =>
      props.disabledClick &&
      `
filter: grayscale(1);
opacity: 0.6;
pointer-events: none;
`}
  `,
  DeviceBox = styled.div`
    margin: 29px auto;
    display: flex;
    width: min-content;
  `,
  PriceCircle = styled.div`
    border-radius: 50%;
    background-color: #f16e00;
    color: #ffffff;
    border: 15px solid #f16e00;
    margin-right: -35px;
    padding: 60%;
    height: 0;
    width: auto;

    @media (max-width: 500px) {
      transform: scale(0.75);
    }
  `,
  PriceContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: 100%;
    justify-content: center;
  `,
  PriceHeader = styled.h1`
    font-size: 20px;
  `,
  PriceBox = styled.div`
    display: flex;
    padding: 8px 0 10px 0;
  `,
  Price = styled.h1`
    font-size: 70px;
    line-height: 60px;
  `,
  Currency = styled.div`
    padding-left: 5px;
    display: flex;

    h1 {
      font-size: 27px;
    }
  `,
  VATinfo = styled.h5`
    font-size: 10px;
    letter-spacing: 0.7px;
    width: 61px;
  `,
  Sentence = styled.h1`
    color: ${(props) => props.theme.colors.orange};
    max-width: 219px;
    margin-top: 12px;
  `,
  ChooseSmartphone = styled.div`
    text-align: center;
    margin-bottom: 45px;
  `,
  ButtonBox = styled.div`
    display: flex;
    align-items: center;
    margin: 14.25px 0;
  `,
  ArrowIcon = styled.img<IArrowIcon>`
    cursor: pointer;
    width: auto;
    height: 27px;
    ${(props) => props.side === 'left' && `margin-right: 27px`};
    ${(props) => props.side === 'right' && `margin-left: 27px`};

    ${(props) =>
      props.notActive &&
      `
    opacity: 0.3;
    cursor: auto;
    `}
  `,
  CheckboxText = styled.h3`
    padding-left: 19.5px;
    margin-block-start: -4px;
  `,
  CircleBox = styled.div`
    padding-left: 35px;

    @media (max-width: 500px) {
      padding-left: 0;
      margin-left: -20px;
    }
  `,
  ChoiceBox = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    line-height: 28px;
  `,
  GraphicsBox = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  PhoneImageBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `,
  SmartphoneImage = styled.img`
    height: 340px;
    width: auto;
    @media (max-width: 500px) {
      margin-left: -40px;
    }
  `,
  NumberOfItem = styled.div`
    font-weight: 500;
  `,
  Box = styled.div``;
