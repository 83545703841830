import styled from 'styled-components';

export const Main = styled.div`
    margin-bottom: 19.5px;
  `,
  PricingSummaryBox = styled.div`
    background-color: ${(props) => props.theme.colors.gray};
    padding: 29px 0;
    margin-top: 15px;
    text-align: center;
  `,
  Pricing = styled.div`
    padding-top: 23.5px;
  `,
  SummaryBox = styled.div`
    text-align: center;
    background-color: ${(props) => props.theme.colors.darkGray};
    padding: 20px 0;
    color: ${(props) => props.theme.colors.white};
  `,
  TriangleDown = styled.div`
    width: 0;
    height: 0;
    border-left: 27px solid transparent;
    border-right: 27px solid transparent;

    border-top: 18px solid ${(props) => props.theme.colors.darkGray};
    margin: -1px auto 0 auto;
  `,
  Price = styled.h2`
    font-size: 20px;
    font-weight: 600;
  `,
  Sentence = styled.h5`
    padding-top: 8px;
  `,
  Plus = styled.h3`
    font-size: 39px;
  `;
