import React, { FunctionComponent, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";

import { RootState } from 'core/store/store';
import { apiService } from 'core/services'
import MessageModal from 'view/components/structure/message';
import Input from 'view/components/content/input';
import Select from 'view/components/content/select';
import Checkbox from 'view/components/content/checkbox';
import Modal from 'view/components/content/modal';
import ButtonForm from 'view/components/content/button-form';
import Warning from 'view/components/content/warning';
import closeIcon from 'assets/close.svg';

import { IForm } from 'view/pages/interfaces';
import { Text } from 'view/components/content/input/styles';
import { Main, CloseBox, Close, ButtonBox, Header, InputsBox, SelectBox, Action, CheckboxText, CheckboxBox, ConsentText } from './styles';

export const ContactRequestFormPage: FunctionComponent<IForm> = ({ isOpen, close }) => {
  const { offerId } = useParams();

  const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
  const [isConsentExpanded, setIsConsentExpanded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const options = useSelector((state: RootState) => state.offers.campaign.availableContactHours);
  const selectOptions = useMemo(() => options.map(x => ({ label: x, value: x })), [options]);

  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = data => {
    setIsLoading(true);
    apiService.postRequestTelephoneContact(offerId, { telephone: data.telephone, hour: data.hour.value }).then(() => {
      setIsMessageModalOpen(true);
      setIsLoading(false);
    }
    );
  };

  return <Modal isModalOpen={isOpen} close={close}>
    <Main >
      <CloseBox>
        <Close src={closeIcon} onClick={() => close()} />
      </CloseBox>
      <MessageModal button="Zamknij" header="Dziękujemy!"
        message="Twoje zgłoszenie zostało poprawnie wysłane."
        isModalOpen={isMessageModalOpen}
        close={() => setIsMessageModalOpen(false)} />
      <Header>Prośba o kontakt</Header>
      <p>Jestem zainteresowana/y ofertą i proszę o kontakt telefoniczny pod poniższym numerem</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputsBox>
          <Input labelText="Wpisz swój numer telefonu" name="telephone" register={register({ required: true })} />
          {errors.telephone && errors.telephone.type === "required" && <Warning text="To pole jest wymagane." />}
        </InputsBox>
        <SelectBox>
          <Text>Wybierz dogodny zakres godzin</Text>
          <Controller as={
            <Select options={selectOptions} placeholder={options[0]} register={register} />} name="hour" options={selectOptions} control={control} />
        </SelectBox>
        <CheckboxBox>
          <Checkbox id="consent" scale={1.2} register={register({ required: true })} name="dataProcessing" />
          <CheckboxText>
            <div>Udzielam zgody na przetwarzanie</div>
            <Action onClick={() => setIsConsentExpanded(!isConsentExpanded)}>{isConsentExpanded ? "Zwiń" : "Rozwiń"}</Action>
            {isConsentExpanded &&
              <ConsentText>
                Udzielam zgody na przetwarzanie przez
                Orange Polska S.A. numeru telefonu
                poprzez jednorazowy kontakt telefoniczny
                w celu marketingu bezpośredniego swoich
                produktów lub usług.
      </ConsentText>
            }
            {errors.dataProcessing && errors.dataProcessing.type === "required" && <Warning text="To pole jest wymagane." />}
          </CheckboxText>
        </CheckboxBox>
        {isLoading && <Warning text="Wysyłam..." />}
        <ButtonBox >
          <ButtonForm buttonStyle="orange" placeholderText="Wyślij" />
        </ButtonBox>
      </form>
    </Main>
  </Modal>;
};
