import styled from 'styled-components';

export const Main = styled.div`
    width: 88%;
    height: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 15px 0 41px 0;
  `,
  ButtonBox = styled.div`
    max-width: 170px;
    margin: 0 auto;
    margin-top: 24px;
  `,
  Breadcrumbs = styled.div`
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 25px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.darkGray};
  `,
  ArrowIcon = styled.img`
    width: 7px;
    transform: rotate(180deg);
    margin-right: 12px;
  `;
