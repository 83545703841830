import React, { FunctionComponent } from 'react';

import Modal from 'view/components/content/modal'
import Button from 'view/components/content/button';
import { IMessagePage } from '../interfaces';
import { MessageMain, ButtonBox } from './styles';

export const MessageModal: FunctionComponent<IMessagePage> = ({ header, isModalOpen, message, button, close }) => {

  return <Modal isModalOpen={isModalOpen} close={close}>
    <MessageMain >
      <div>
        <h1>{header}</h1>
        <p>{message}</p>
      </div>
      <ButtonBox>
        <Button buttonStyle="orange" >
          <div onClick={() => close()}>{button}</div>
        </Button>
      </ButtonBox>
    </MessageMain >
  </Modal>;
};

export default MessageModal;
